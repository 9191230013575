<script lang="ts" setup>
const { t } = useT();
</script>
<template>
	<div class="box-no-result text-neutral-50">
		<AText class="title" :size="16" :modifiers="['bold']" as="h3">
			{{ t("Search results:") }}
		</AText>

		<NuxtImg
			class="img"
			src="/nuxt-img/games/no-result.png"
			alt="no-result"
			width="156"
			height="157"
			format="avif"
			loading="lazy"
		/>

		<AText :modifiers="['normal']" class="txt text-neutral-200">
			{{ t("Oops...Nothing was found for your request.") }}
		</AText>
		<AText :size="12" :modifiers="['semibold', 'center']" class="subtxt text-neutral-500">
			{{ t("Try something from the games below") }}
		</AText>
	</div>
</template>
<style lang="scss" scoped>
.box-no-result {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.title {
	display: flex;
	align-items: center;
	min-height: 24px;
}

.img {
	width: 104px;
	height: 104px;
	margin: auto;
}

.subtxt {
	display: none;
}
</style>
